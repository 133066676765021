<template>
  <!-- News Form -->
  <div v-if="form_visibility" class="news-editor bg-gray-50">
    <div
      class="p-4 pt-12 container max-w-screen-lg mx-auto bg-white shadow mb-6"
    >
      <button class="trigger-hide" @click="hideForm()">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="news-editor__heading">
        <div class="container max-w-screen-lg mx-auto grid grid-cols-2 gap-2">
          <h1 class="p-2 uppercase text-left tracking-wider md:text-xl">
            {{ isEditing ? "edit" : "tulis" }} berita
          </h1>
          <div class="text-right pr-3">
            <!-- trigger add new period -->
            <button class="button --primary" @click="submit()">
              <fa-icon icon="save"></fa-icon>
              <span class="ml-2">simpan</span>
            </button>
          </div>
        </div>
      </div>

      <!-- image uploader -->
      <div v-if="form.id !== null" class="mt-4 mb-8">
        <img
          :src="form.thumbnail_url_full"
          alt="Belum ada foto"
          id="form-image"
          style="min-height: 150px"
          class="border shadow rounded border-gray-200 mx-auto text-gray-500 text-center"
        />
        <image-uploader
          ref="imageUploader"
          text="Unggah"
          :ratio="16 / 9"
          @ready="triggerUploadImage"
        ></image-uploader>
      </div>

      <form @submit.prevent="submit()" class="grid grid-cols-1 gap-2">
        <!-- news title -->
        <div class="mb-4">
          <label for="news-name" class="form-label">Judul</label>
          <input
            type="text"
            id="news-name"
            class="w-full"
            v-model="form.title"
          />
        </div>

        <div class="mb-4 grid grid-cols-2">
          <aside>
            <input
              type="checkbox"
              id="news-published"
              v-model="form.is_published"
            />
            <label for="news-published" class="form-label-line ml-2"
              >publikasi</label
            >
          </aside>
          <aside>
            <input
              type="checkbox"
              id="news-highlighted"
              v-model="form.is_highlighted"
            />
            <label for="news-highlighted" class="form-label-line ml-2"
              >Program Unggulan</label
            >
          </aside>
        </div>

        <!-- news content -->
        <div class="mb-4">
          <label for="news-name" class="form-label">konten</label>
          <toast-editor
            class="mb-2"
            preview-style="tab"
            ref="toastuiEditor"
            initial-value=""
            :height="editor.height"
            :options="editor.options"
            @change="onEditorChange"
          ></toast-editor>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import { Editor } from "@toast-ui/vue-editor";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _clone from "lodash/clone";
import _debounce from "lodash/debounce";

export default {
  data() {
    return {
      form: {
        id: null,
        title: null,
        content: null,
        is_published: false,
        is_highlighted: false,
        image_url: null,
      },
      formDefault: {},
      editor: {
        options: {
          usageStatistics: false,
          hideModeSwitch: true,
        },
        height: "500px",
      },
    };
  },
  methods: {
    /**
     * Markdown's
     */
    getContent() {
      return this.$refs.toastuiEditor.invoke("getMarkdown");
    },
    setContent(content) {
      return this.$refs.toastuiEditor.invoke("setMarkdown", content);
    },
    onEditorChange: _debounce(
      function () {
        this.form.content = this.getContent();
      },
      700,
      { maxWait: 1000 }
    ),

    async submit() {
      if (this.form.content === null || this.form.title === null) {
        this.$toasted.error("Judul dan Konten harus diisi terlebih dahulu");
        return;
      }

      if (this.form.id === null) {
        await this.storeNews(this.form);
      } else {
        await this.updateNews(this.form);
      }
      this.hideForm();
    },

    async triggerUploadImage(file) {
      const formData = new FormData();
      formData.append("image", file);

      await this.updateNewsPhoto({
        item: this.form,
        data: formData,
      });

      // reload image
      let newsId = this.form.id;
      const reader = new FileReader();
      reader.onload = function (e) {
        let imageContainer = document.getElementById(`news-image-${newsId}`);
        imageContainer.src = e.target.result;

        let formImageContainer = document.getElementById("form-image");
        formImageContainer.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    hideForm() {
      this.HIDE_FORM();
      this.UNSET_SELECTED_NEWS();

      if (this.form.id !== null) {
        // clear image preview on form
        let formImageContainer = document.getElementById("form-image");
        formImageContainer.src = null;
      }

      this.form = _clone(this.formDefault);
    },

    ...mapActions("news", ["storeNews", "updateNews", "updateNewsPhoto"]),

    ...mapMutations("news", ["HIDE_FORM", "UNSET_SELECTED_NEWS"]),
  },
  computed: {
    isEditing() {
      return this.form.id !== null;
    },
    ...mapGetters("news", ["selected_news", "form_visibility"]),
  },
  watch: {
    selected_news(val) {
      if (val === null) {
        this.form = _clone(this.formDefault);
      } else {
        this.form = _clone(val);
        this.$nextTick(function () {
          this.setContent(val.content);
        });
      }
    },
  },

  mounted() {
    this.formDefault = _clone(this.form);
  },

  components: {
    ImageUploader: () => import("@/components/Common/ImageUploader.vue"),
    toastEditor: Editor,
  },
  name: "NewsEditor",
};
</script>

<style lang="scss" scoped>
.news-editor {
  @apply fixed top-0 left-0 z-30;
  @apply overflow-scroll h-screen w-full;
  @apply p-4 pt-32;
}

.news-editor__heading {
  @apply mb-10  shadow;
  @apply fixed w-full top-0 left-0 pb-4 px-4 pt-10 z-10 bg-white;
}

.trigger-hide {
  @apply fixed left-3 -mt-2 top-0 w-12 h-12 z-20;
  @apply shadow-lg text-white text-xl;
  @apply rounded-b-full bg-gray-800 bg-opacity-80;
}

.form-label,
.form-label-line {
  @apply uppercase text-sm mb-1 tracking-wider;
}

.form-label {
  @apply block;
}
</style>